import "vuetify/styles"; // Global CSS has to be imported
import { createVuetify, type ThemeDefinition } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import { createI18n } from "vue-i18n";
import nbMessages from "../../lang/nb.json";
import enMessages from "../../lang/en.json";
import { mdiInformation } from "@mdi/js";
import { no, en } from "vuetify/locale";
import { md2 } from "vuetify/blueprints";
import DayJsAdapter from "@date-io/dayjs";
import dayJsNb from "dayjs/locale/nb";
import dayJsEn from "dayjs/locale/en-gb";

const messages = {
    en: enMessages,
    nb: nbMessages,
};

export const i18n = createI18n({
    legacy: false, // Vuetify does not support the legacy mode of vue-i18n
    locale: "nb",
    fallbackLocale: "en",
    messages,
});

const pniTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: "#ffffff",
        surface: "#ffffff",
        primary: "#07796a",
        "primary-darken-1": "#055e53",
        "primary-darken-2": "#064840",
        secondary: "#f36d29",
        "secondary-darken-1": "#d75e20",
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
    },
};

export const vuetify = createVuetify({
    blueprint: md2,
    defaults: {
        VTextField: {
            variant: "outlined",
            color: "secondary",
        },
        VTextarea: {
            variant: "outlined",
        },
        VAutocomplete: {
            variant: "outlined",
        },
        VSelect: {
            variant: "outlined",
        },
        VPagination: {
            rounded: "md",
        },
        VCheckbox: {
            color: "secondary",
        },
    },
    theme: {
        defaultTheme: "pniTheme",
        variations: {
            colors: ["primary", "secondary"],
            lighten: 1,
            darken: 2,
        },
        themes: { pniTheme },
    },
    icons: {
        defaultSet: "mdi",
        aliases: {
            ...aliases,
            primary: mdiInformation,
        },
        sets: {
            mdi,
        },
    },
    display: {
        mobileBreakpoint: "sm",
        thresholds: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1264,
            xl: 1904,
        },
    },
    locale: {
        locale: "nb",
        fallback: "en",
        messages: {
            nb: no,
            en,
        },
    },
    date: {
        adapter: DayJsAdapter,
        locale: {
            no: dayJsNb,
            en: dayJsEn,
        },
    },
});
