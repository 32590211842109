import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { vuetify, i18n } from "./vuetify";
import dayjs from "dayjs";
import "dayjs/locale/nb";
import "dayjs/locale/en-gb";
import localizedFormat from "dayjs/plugin/localizedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import { displaySentryUserFeedback } from "./sentry";
import calendar from "dayjs/plugin/calendar";

dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

dayjs.updateLocale("nb", {
    formats: {
        L: "DD.MM.YY",
        LL: "DD.MM.YYYY",
        LLL: "DD. MMMM YYYY",
        LT: "HH:mm",
    },
    calendar: {
        sameDay: "[i dag kl.] LT",
        nextDay: "[i morgen kl.] LT",
        nextWeek: "dddd [kl.] LT",
        lastDay: "[i går kl.] LT",
        lastWeek: "dddd [som var] [kl.] LT",
        sameElse: "LL",
    },
});

dayjs.updateLocale("en-gb", {
    formats: {
        L: "DD/MM/YY",
        LL: "DD/MM/YYYY",
        LLL: "DD MMMM YYYY",
    },
    calendar: {
        sameDay: "[today at] LT",
        nextDay: "[tomorrow at] LT",
        nextWeek: "dddd [at] LT",
        lastDay: "[yesterday at] LT",
        lastWeek: "[last] dddd [at] LT",
        sameElse: "LL",
    },
});

dayjs.locale("nb");

const pinia = createPinia();

createInertiaApp({
    progress: {
        color: "#f36d29",
        showSpinner: true,
    },
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            (import.meta as any).glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(vuetify)
            .use(i18n)
            .use(pinia);

        Sentry.init({
            app,
            dsn: CONFIG?.sentry?.dsn,
            beforeSend(event, hint) {
                if (event.exception) {
                    if (event.event_id) {
                        displaySentryUserFeedback(
                            event.event_id,
                            i18n.global.t,
                            event.user?.username,
                            event.user?.email
                        );
                    }
                }

                return event;
            },
            integrations: [
                new Sentry.Replay({
                    maskAllText: true,
                    blockAllMedia: true,
                }),
            ],
            logErrors: true,
            tracesSampleRate: 1.0,
            replaysOnErrorSampleRate: 1.0,
            replaysSessionSampleRate: 1.0,
        });

        app.mount(el);
    },
});
