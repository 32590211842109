import type { I18n } from "vue-i18n";
import * as Sentry from "@sentry/vue";
import type User from "./types/User";

export function displaySentryUserFeedback(
    eventId: string,
    t: Function,
    username?: string,
    email?: string
) {
    Sentry.showReportDialog({
        eventId: eventId,
        user: {
            name: username,
            email: email,
        },
        title: t("It looks like there is an issue with the system"),
        subtitle: t(
            "Developers have been notified. If you'd like to help, tell us what happened below."
        ),
        labelName: t("Name"),
        labelEmail: t("Email"),
        labelSubmit: t("Send"),
        labelClose: t("Close"),
        labelComments: t("What happened?"),
        errorGeneric: t(
            "An unknown error occurred while submitting your report. Please try again."
        ),
        errorFormEntry: t(
            "Some fields were invalid. Please correct the errors and try again."
        ),
        successMessage: t("Your feedback has been sent. Thank you!"),
    });
}
